// Import statements
import {
  EntityTypes,
  formatCurrency,
  InventoryChange,
  InventoryChangeReason,
} from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

// Custom table header component
const CustomTh = chakra('th', {
  baseStyle: {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

interface TableRowProps extends InventoryChange {
  show_product_name?: boolean;
}

// TableRow component
const TableRow: React.FC<TableRowProps> = ({
  change_quantity: adjustment,
  created_at,
  cumulative_quantity: after_stock,
  reason,
  cost,
  source_name,
  source_id,
  product_name,
  show_product_name = true,
  purchase_order_details,
  source_type,
}) => {
  const cost_difference = adjustment * cost;

  return (
    <Tr fontSize={'sm'}>
      {/* Date and User */}
      <Td>
        <Box>
          {show_product_name && (
            <Text fontWeight={'bold'} fontSize={'xs'}>
              {product_name}
            </Text>
          )}
          <Text
            fontWeight={show_product_name ? 'normal' : 'bold'}
            fontSize={'xs'}>
            {moment(created_at).format('DD.MM.YY @ hh:mm')}
          </Text>
          <Text fontSize={'xs'}>{source_name ?? 'UNKNOWN'}</Text>
        </Box>
      </Td>

      {/* Adjustment, After Stock, Cost Difference */}
      <Td>
        <Box position="relative" minW="100px" h="50px">
          <Text
            textTransform={'lowercase'}
            fontSize="xs"
            position="absolute"
            left="30%"
            top="0"
            color={adjustment > 0 ? 'green.500' : 'red.500'}>
            {adjustment > 0 ? '+' : ''}
            {adjustment.toFixed(2)}
          </Text>
          <Text
            fontWeight={'bold'}
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)">
            {after_stock.toFixed(2)}
          </Text>
          <Text
            fontSize="xs"
            position="absolute"
            right="30%"
            bottom="0"
            color={cost_difference > 0 ? 'green.500' : 'red.500'}>
            {formatCurrency(cost_difference, false)}
          </Text>
        </Box>
      </Td>

      {/* Reason */}
      <Td>
        <Text fontWeight={'bold'} textAlign={'center'} fontSize={'xs'}>
          {reason.replace(/Inventory/g, '').toUpperCase()}
        </Text>
        {[
          InventoryChangeReason.SALE,
          InventoryChangeReason.REFUND,
          InventoryChangeReason.PURCHASE_ORDER,
        ].includes(reason) &&
          (purchase_order_details || source_type === EntityTypes.RECEIPT) && (
            <Text
              fontWeight={'bold'}
              color={'blue'}
              textAlign={'center'}
              fontSize={'xx-small'}>
              {purchase_order_details
                ? purchase_order_details.id.toUpperCase()
                : source_id.toUpperCase()}
            </Text>
          )}
      </Td>
    </Tr>
  );
};

// InventoryHistoryTable component
export const InventoryHistoryTable: React.FC<{
  data: InventoryChange[];
  show_product_name?: boolean;
}> = ({ data, show_product_name }) => {
  return data.length ? (
    <TableContainer width="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr fontSize={'sm'}>
            <CustomTh></CustomTh>
            <CustomTh>
              <Box position="relative" p={2} minW="100px" h="50px">
                <Text
                  textTransform={'lowercase'}
                  fontSize="xs"
                  position="absolute"
                  left="0"
                  top="0">
                  adj
                </Text>
                <Text
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)">
                  After Stock
                </Text>
                <Text
                  textTransform={'lowercase'}
                  fontSize="xs"
                  position="absolute"
                  right="0"
                  bottom="0">
                  cost diff
                </Text>
              </Box>
            </CustomTh>
            <CustomTh>Reason</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              {...row}
              show_product_name={show_product_name}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Alert textTransform={'capitalize'} width={'auto'} status="info">
      <AlertIcon />
      No History Found
    </Alert>
  );
};
