// Import statements
import { formatCurrency, StoreProductChange } from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

// Custom table header component
const CustomTh = chakra('th', {
  baseStyle: {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

// TableRow component
const TableRow: React.FC<StoreProductChange> = ({
  created_at,
  updator_name,
  changed_attributes,
}) => {
  const keyOfChange = Object.keys(changed_attributes)[0];
  const _old = changed_attributes[keyOfChange].old;
  const _new = changed_attributes[keyOfChange].new;

  const diff = _new - _old;

  let oldValue = _old;
  let newValue = _new;

  let isCurrency = false;

  if (
    ['price', 'cost', 'max_selling_price', 'min_selling_price'].includes(
      keyOfChange,
    )
  ) {
    oldValue = formatCurrency(oldValue, false);
    newValue = formatCurrency(newValue, false);
    isCurrency = true;
  }

  const fomattedKeyOfChange = keyOfChange.replace(/_/g, ' ');

  const capitalizeAllFirstLetters = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <Tr fontSize={'sm'}>
      {/* Date and User */}
      <Td>
        <Box>
          <Text pt={1} fontWeight={'bold'} fontSize={'xs'}>
            {capitalizeAllFirstLetters(fomattedKeyOfChange) ?? 'UNKNOWN'}
          </Text>
          <Text fontSize={'xs'}>{updator_name ?? 'UNKNOWN'}</Text>
          <Text fontSize={'xs'}>
            {moment(created_at).format('DD.MM.YY @ hh:mm')}
          </Text>
        </Box>
      </Td>

      {/* Old Value */}
      <Td>
        <Text textAlign={'center'} fontSize={'sm'}>
          {oldValue}
        </Text>
      </Td>

      {/* New Value */}
      <Td>
        <Box>
          <Text fontSize={'sm'}>{newValue}</Text>
          {isCurrency && (
            <Text
              color={diff > 0 ? 'green.500' : diff < 0 ? 'red.500' : 'gray.500'}
              fontSize={'xs'}>
              {formatCurrency(diff, false)}
            </Text>
          )}
        </Box>
      </Td>
    </Tr>
  );
};

export const ProductHistoryTable: React.FC<{
  data: StoreProductChange[];
}> = ({ data }) => {
  return data.length ? (
    <TableContainer width="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr fontSize={'sm'}>
            <CustomTh></CustomTh>
            <CustomTh>Old Value</CustomTh>
            <CustomTh>New Value</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <TableRow key={row.id} {...row} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Alert textTransform={'capitalize'} width={'auto'} status="info">
      <AlertIcon />
      No Price History Found
    </Alert>
  );
};
