// Import statements
import { formatCurrency, InventoryChange } from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

// Custom table header component
const CustomTh = chakra('th', {
  baseStyle: {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

// TableRow component
const TableRow: React.FC<InventoryChange> = ({
  change_quantity: adjustment,
  created_at,
  cost,
  source_name,

  purchase_order_details,
}) => {
  return (
    <Tr fontSize={'sm'}>
      {/* Date and User */}
      <Td>
        <Box>
          <Text pt={1} fontWeight={'bold'} fontSize={'xs'}>
            {purchase_order_details?.id ?? 'UNKNOWN'}
          </Text>
          <Text fontSize={'xs'}>{source_name ?? 'UNKNOWN'}</Text>
          <Text fontSize={'xs'}>
            {moment(created_at).format('DD.MM.YY @ hh:mm')}
          </Text>
        </Box>
      </Td>

      {/* Supplier*/}
      <Td>
        <Box>
          <Text fontSize={'sm'}>
            {purchase_order_details?.supplier.name ?? 'UNKNOWN'}
          </Text>
          {purchase_order_details?.supplier.phone_number && (
            <Button
              size="xs"
              mt={1}
              p="0px"
              onClick={
                purchase_order_details?.supplier.phone_number
                  ? () =>
                      window.open(
                        `tel:${purchase_order_details?.supplier.phone_number}`,
                      )
                  : undefined
              }
              leftIcon={<FaPhoneAlt />}
              borderWidth="0px"
              variant="outline">
              {purchase_order_details?.supplier.phone_number}
            </Button>
          )}
        </Box>
      </Td>

      {/* Quantity */}
      <Td>
        <Text textAlign={'center'} fontSize={'xs'}>
          {adjustment}
        </Text>
      </Td>

      {/* Cost */}
      <Td>
        <Text textAlign={'center'} fontSize={'xs'}>
          {formatCurrency(cost, false)}
        </Text>
      </Td>
    </Tr>
  );
};

// InventoryHistoryTable component
export const ProductPurchaseHistoryTable: React.FC<{
  data: InventoryChange[];
}> = ({ data }) => {
  return data.length ? (
    <TableContainer width="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr fontSize={'sm'}>
            <CustomTh></CustomTh>
            <CustomTh>Supplier</CustomTh>
            <CustomTh>Quantity</CustomTh>
            <CustomTh>Cost</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <TableRow key={row.id} {...row} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Alert textTransform={'capitalize'} width={'auto'} status="info">
      <AlertIcon />
      No Purchase History Found
    </Alert>
  );
};
