import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosRetryConfig } from './functions';

import {
  Action,
  ActionList,
  AggregateEmployeeSalesReport,
  AggregateProductSalesReport,
  AggregateSalesReport,
  AggregateStoreSalesReport,
  AuthenticateUserRequest,
  AuthenticateUserResponse,
  BofrakOkResponse,
  BofrakUploadImageProps,
  CalculateDiscount,
  CalculateDiscountResult,
  CalculateTax,
  CalculateTaxResult,
  CreateAuthUserResponse,
  CreateCustomer,
  CreateDiscount,
  CreateImage,
  CreateInventoryChange,
  CreateInventoryTemplate,
  CreateLoyaltyProgram,
  CreateMerchant,
  CreateMerchantUserRequestBody,
  CreatePaymentType,
  CreatePosDevice,
  CreateProduct,
  CreateProductFraction,
  CreatePurchaseOrder,
  CreatePurchaseOrderPayment,
  CreateStore,
  CreateStoreProduct,
  CreateSupplier,
  CreateTax,
  CreateThreshold,
  CreateUser,
  CurrentInventory,
  Customer,
  CustomerPage,
  Debt,
  DebtCommand,
  DebtorSummary,
  DebtsPage,
  DecryptCredentialsRequestBody,
  Discount,
  DiscountPage,
  Employee,
  EmployeePage,
  EncryptCredentialsDto,
  EntityTypes,
  GetAllPurchaseOrders,
  GetDebts,
  GetInventoryChanges,
  GetInventoryTemplates,
  GetProducts,
  GetProductsInventoryValue,
  GetReceipts,
  GetSalesReport,
  GetSalesReportByDateRangeIntervalOptions,
  GetStoreProductChanges,
  Image,
  IntervalAggregateReport,
  InventoryChangePage,
  InventoryTemplate,
  InventoryTemplatesPage,
  LoyaltyProgram,
  LoyaltyProgramPage,
  Merchant,
  MerchantPage,
  MerchantUserAttributes,
  PaymentType,
  PaymentTypePage,
  Policy,
  PolicyList,
  PosDevice,
  PosDevicePage,
  Principal,
  PrincipalList,
  Product,
  ProductFraction,
  ProductFractionPage,
  ProductInventoryValuePage,
  ProductPage,
  PurchaseOrder,
  PurchaseOrderPage,
  PurchaseOrderPayment,
  PurchaseOrderPaymentPage,
  Receipt,
  ReceiptsPage,
  ReceivePurchaseOrder,
  RedeemPoints,
  Resource,
  ResourceList,
  Role,
  RoleList,
  SignOutUserRequestBody,
  SignOutUserResponse,
  Store,
  StoreInventoryValue,
  StorePage,
  StoreProduct,
  StoreProductChangesPage,
  StoreProductPage,
  Supplier,
  SupplierPage,
  Tax,
  TaxPage,
  Threshold,
  UpdateCustomer,
  UpdateDiscount,
  UpdateInventoryTemplate,
  UpdateLoyaltyAfterSaleOrRefund,
  UpdateLoyaltyProgram,
  UpdateMerchant,
  UpdateMerchantUserRequestBody,
  UpdatePaymentAllocations,
  UpdatePaymentType,
  UpdatePosDevice,
  UpdateProduct,
  UpdateProductFraction,
  UpdatePurchaseOrder,
  UpdateReceipt,
  UpdateStore,
  UpdateStoreProduct,
  UpdateStoreProductInventory,
  UpdateSupplier,
  UpdateTax,
  UpdateThreshold,
  UpdateUser,
} from '@bofrak-backend/shared';
import { ZodError } from 'zod';

export class ShopAndSmileAPIAdapter {
  private readonly apiEndpoint: string;
  private readonly accessToken?: string;
  private readonly retryCount?: number;

  // Constructor accepting either a string or an object with apiEndpoint, accessToken, and retryCount
  constructor(
    config:
      | string
      | { apiEndpoint: string; accessToken?: string; retryCount?: number },
  ) {
    if (typeof config === 'string') {
      // If a string is provided, treat it as the apiEndpoint
      this.apiEndpoint = config;
    } else {
      // If an object is provided, destructure the properties
      const { apiEndpoint, accessToken, retryCount } = config;
      this.apiEndpoint = apiEndpoint;
      this.accessToken = accessToken;
      this.retryCount = retryCount;
    }

    // Pass retryCount to axiosRetryConfig if provided
    axiosRetryConfig(axios, this.retryCount);
  }
  /**
   * Logs the error response from Axios.
   */

  public logAxiosError(error: any): void {
    console.log(
      '---------------------------AXIOS ERROR-------------------------------',
    );
    if (axios.isAxiosError(error)) {
      // Handle Axios errors
      console.error('Axios error:', {
        message: error.message,
        code: error.code,
        url: error.config?.url,
        method: error.config?.method,
        requestHeaders: error.config?.headers,
        requestData: error.config?.data
          ? JSON.parse(error.config.data)
          : error.config?.data,
        response: error.response
          ? {
              status: error.response.status,
              data: error.response.data,
            }
          : null,
      });
    } else if (error instanceof ZodError) {
      // Handle Zod validation errors
      console.error('Zod validation error:', {
        issues: error.issues.map((issue) => ({
          path: issue.path,
          message: issue.message,
          code: issue.code,
        })),
      });
    } else if (error instanceof Error) {
      // Handle generic JavaScript errors
      console.error('General error:', {
        message: error.message,
        stack: error.stack,
      });
    } else {
      // Handle unknown errors
      console.error('Unknown error:', error);
    }
  }

  /**
   * Initializes the Axios request configuration.
   * @param method - HTTP method
   * @param url - API endpoint URL
   * @param data - Data to be sent in the request body
   * @param extraHeaders - Additional headers
   * @returns AxiosRequestConfig object
   */
  public getRequestConfig({
    method,
    data,
    extraHeaders,
    url,
  }: {
    method: string;
    url: string;
    data: any;
    extraHeaders: object;
  }): AxiosRequestConfig {
    return {
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
        ...extraHeaders,
        // Add access key to headers if it exists
        ...(this.accessToken && {
          Authorization: `Bearer ${this.accessToken}`,
        }),
      },
      ...(data && { data: JSON.stringify(data) }),
    };
  }

  /**
   * Converts the query parameters to a query string.
   * @param params - The query parameters.
   * @returns The query string.
   */

  // Helper method to convert an object to a query string
  public toQueryString(
    params: Record<string, any>,
    splitArrayToCsv?: boolean,
  ): string {
    return Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .map((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          const filteredValues = value.filter((v) => v !== undefined);
          if (splitArrayToCsv) {
            // Join array elements with commas and encode the entire string
            return `${encodeURIComponent(key)}=${encodeURIComponent(filteredValues.join(','))}`;
          } else {
            // Serialize each array element as a separate key=value pair
            return filteredValues
              .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
              .join('&');
          }
        } else {
          // Serialize non-array values normally
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
  }

  /**
   * Get a list of Merchants from the API.
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of merchants to return
   * @param store_id - Store ID to filter merchants
   * @returns Promise object representing the MerchantPage
   * @throws Error if the request fails
   * @see MerchantPage
   */
  public async getMerchants({
    cursor,
    limit,
    store_id,
  }: {
    cursor?: string;
    limit?: number;
    store_id?: string;
  }): Promise<MerchantPage> {
    let url = `${this.apiEndpoint}/merchants`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
      store_id,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as MerchantPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Merchant in the API.
   * @param dto - Create Merchant DTO
   * @returns Promise object representing the Merchant
   * @throws Error if the request fails
   * @see Merchant
   * @see CreateMerchant
   *
   */

  public async createMerchant(dto: CreateMerchant): Promise<Merchant> {
    const url = `${this.apiEndpoint}/merchants`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Merchant> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single Merchant from the API.
   * @param id - Merchant ID
   * @returns Promise object representing the Merchant
   * @throws Error if the request fails
   * @see Merchant
   */

  public async getMerchant(id: string): Promise<Merchant> {
    const url = `${this.apiEndpoint}/merchants/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Merchant> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single Customer from the API.
   * @param id - Customer ID
   * @returns Promise object representing the Customer
   * @throws Error if the request fails
   * @see Customer
   */

  public async getCustomer(id: string, merchant_id: string): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get Customer By Phone
   * @param phone_number - Phone number
   * @returns Promise object representing the Customer
   * @throws Error if the request fails
   * @see Customer
   *
   */

  public async getCustomerByPhone(phone_number: string): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers?phone_number=${phone_number}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Users from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of users to return
   * @param role - User role to filter users
   */

  public async getUsers({
    merchant_id,
    cursor,
    limit,
    role,
    store_id,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
    role?: string;
    store_id?: string;
  }): Promise<EmployeePage> {
    let url = `${this.apiEndpoint}/users`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
      role,
      merchant_id,
      store_id,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<EmployeePage> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single User from the API.
   * @param user_id - User ID
   * @returns Promise object representing the User
   * @throws Error if the request fails
   * @see Employee
   */

  public async getUser(user_id: string): Promise<Employee> {
    const url = `${this.apiEndpoint}/users/${user_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Employee> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Store in the API.
   * @param dto - Create Store DTO
   * @returns Promise object representing the Store
   * @throws Error if the request fails
   */

  public async createStore(dto: CreateStore): Promise<Store> {
    const url = `${this.apiEndpoint}/stores`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Store> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update a Store in the API.
   * @param dto - Update Store DTO
   * @returns Promise object representing the Store
   * @throws Error if the request fails
   * @see Store
   */

  public async updateStore(dto: UpdateStore): Promise<Store> {
    const url = `${this.apiEndpoint}/stores`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Store> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get A single Store from the API.
   * @param store_id - Store ID
   * @returns Promise object representing the Store
   * @throws Error if the request fails
   * @see Store
   */

  public async getStore(store_id: string, merchant_id: string): Promise<Store> {
    const url = `${this.apiEndpoint}/stores/${merchant_id}/${store_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Store> = await axios.request(config);

      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Store
   * @param merchant_id - Merchant ID
   * @returns Promise object representing the Store
   * @throws Error if the request fails
   * @see StorePage
   */

  public async getStores(merchant_id: string): Promise<StorePage> {
    const url = `${this.apiEndpoint}/stores/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StorePage> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Allocate payments to various entities.
   * @param allocations - Payment allocations
   * @returns Promise object
   * @throws Error if the request fails
   * @see UpdatePaymentAllocations
   */

  public async allocatePayments(
    allocations: UpdatePaymentAllocations,
  ): Promise<void> {
    const url = `${this.apiEndpoint}/payments/allocations`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: allocations,
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Back up an Image to the API.
   * @param sku - SKU of the item
   * @param image_url - URL of the image
   * @returns Promise object
   * @throws Error if the request fails
   */

  public async backupImage({
    sku,
    image_url,
  }: {
    sku: string;
    image_url: string;
  }): Promise<any> {
    const url = `${this.apiEndpoint}/loyverse/store-image`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: { sku, image_url },
      extraHeaders: {},
    });

    try {
      return await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Receipt.
   * @param itemData - Receipt
   * @returns Promise object
   * @throws Error if the request fails
   * @see Receipt
   * @see Receipt
   */

  public async createReceipt(itemData: Receipt): Promise<Receipt> {
    const url = `${this.apiEndpoint}/receipts/${itemData.merchant_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: itemData,
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<Receipt> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a Receipt.
   * @param receiptId - Receipt ID
   * @returns Promise object
   * @throws Error if the request fails
   * @see Receipt
   */

  public async getReceipt(
    receiptId: string,
    merchantId: string,
  ): Promise<Receipt> {
    const url = `${this.apiEndpoint}/receipts/${merchantId}/${receiptId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<Receipt> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a list of Receipts.
   * @param queryParams - Query parameters
   * @returns Promise object
   */

  public async getReceipts(
    queryParams: GetReceipts,
    merchantId: string,
  ): Promise<ReceiptsPage> {
    let url = `${this.apiEndpoint}/receipts/${merchantId}`;

    // Add query parameters to the URL
    const query = this.toQueryString(queryParams);
    if (query) url += `?${query}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<ReceiptsPage> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update a Receipt.
   * @param UpdateReceipt - Update Receipt DTO
   * @returns Promise object
   * @throws Error if the request fails
   */

  public async updateReceipt(dto: UpdateReceipt): Promise<Receipt> {
    const url = `${this.apiEndpoint}/receipts/${dto.merchant_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<Receipt> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create User in the API.
   * @param dto - Create User DTO
   * @returns Promise object
   * @throws Error if the request fails
   * @see CreateUser
   * @see Employee
   */

  public async createUser(dto: CreateUser): Promise<Employee> {
    const url = `${this.apiEndpoint}/users`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<Employee> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update User in the API.
   * @param dto - Update User DTO
   * @returns Promise object
   * @throws Error if the request fails
   * @see Employee
   */

  public async updateUser(dto: UpdateUser): Promise<Employee> {
    const url = `${this.apiEndpoint}/users`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<Employee> = await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Upload Image
   * @param data - Image data
   * @returns Promise Image
   */

  public async uploadImage(data: CreateImage): Promise<Image> {
    const { merchant_id, entity_type, entity_id } = data;

    const url = `${this.apiEndpoint}/images/${merchant_id}/${entity_type}/${entity_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Image> = await axios.request(config);

      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Upload Item image from url
   * @param itemData - Item image data
   * @returns Promise object
   * @throws Error if the request fails
   * @see BofrakOkResponse
   * @see ItemImageUploadDto
   */

  public async uploadItemImageFromUrl(
    itemData: BofrakUploadImageProps,
  ): Promise<BofrakOkResponse> {
    const url = `${this.apiEndpoint}/loyverse/items/image/upload`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: itemData,
      extraHeaders: {},
    });

    try {
      const response: AxiosResponse<BofrakOkResponse> =
        await axios.request(config);
      return response.data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get User By Phone
   * @param phone_number - Phone number
   * @returns Promise object representing the Employee
   * @throws Error if the request fails
   * @see Employee
   */

  public async getUserByPhone(phone_number: string): Promise<Employee> {
    const url = `${this.apiEndpoint}/users/phones/${phone_number}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Employee> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update Merchant
   * @param dto - UpdateMerchant object
   * @returns Promise object representing the Merchant
   * @throws Error if the request fails
   * @see Merchant
   * @see UpdateMerchant
   */

  public async updateMerchant(dto: UpdateMerchant): Promise<Merchant> {
    const url = `${this.apiEndpoint}/merchants`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Merchant> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create Merchant User In Cognito
   * @param dto - CreateMerchantUserRequestBody object
   * @throws Error if the request fails
   * @see CreateMerchantUserRequestBody
   * @see CreateAuthUserResponse
   *
   */

  public async createMerchantUserInCognito(
    dto: CreateMerchantUserRequestBody,
  ): Promise<CreateAuthUserResponse> {
    const url = `${this.apiEndpoint}/authentications/merchants`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<CreateAuthUserResponse> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update Merchant User In Cognito
   * @param dto - UpdateMerchantUserRequestBody object
   * @throws Error if the request fails
   * @see UpdateMerchantUserRequestBody
   *
   */

  public async updateMerchantUserInCognito(
    dto: UpdateMerchantUserRequestBody,
  ): Promise<void> {
    const url = `${this.apiEndpoint}/authentications/merchants`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get Merchant User From Cognito
   * @param user_id - User ID
   * @returns Promise object representing the MerchantUserAttributes
   * @throws Error if the request fails
   * @see MerchantUserAttributes
   */

  public async getMerchantUserFromCognito(
    user_id: string,
  ): Promise<MerchantUserAttributes> {
    const url = `${this.apiEndpoint}/authentications/merchants/${user_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<MerchantUserAttributes> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Taxes from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of taxes to return
   * @returns Promise object representing the TaxPage
   * @throws Error if the request fails
   * @see TaxPage
   */
  public async getTaxes({
    merchant_id,
    cursor,
    limit,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
  }): Promise<TaxPage> {
    let url = `${this.apiEndpoint}/taxes/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as TaxPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single Tax from the API.
   * @param merchant_id - Merchant ID
   * @param id - Tax ID
   * @returns Promise object representing the Tax
   * @throws Error if the request fails
   * @see Tax
   */
  public async getTax({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<Tax> {
    const url = `${this.apiEndpoint}/taxes/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Tax> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Tax in the API.
   * @param createTaxDto - CreateTax object
   * @returns Promise object representing the created Tax
   * @throws Error if the request fails
   * @see Tax
   * @see CreateTax
   */
  public async createTax(createTaxDto: CreateTax): Promise<Tax> {
    const url = `${this.apiEndpoint}/taxes`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createTaxDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Tax> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update an existing Tax in the API.
   * @param updateTaxDto - UpdateTax object
   * @returns Promise object representing the updated Tax
   * @throws Error if the request fails
   * @see Tax
   * @see UpdateTax
   */
  public async updateTax(updateTaxDto: UpdateTax): Promise<Tax> {
    const url = `${this.apiEndpoint}/taxes`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateTaxDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Tax> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete a Tax from the API.
   * @param merchant_id - Merchant ID
   * @param id - Tax ID
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteTax({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/taxes/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Calculate taxes based on item price and tax rates.
   * @param calculateTaxDto - CalculateTax object
   * @returns Promise object representing the calculated tax result
   * @throws Error if the request fails
   * @see CalculateTaxResult
   * @see CalculateTax
   */
  public async calculateTaxes(
    calculateTaxDto: CalculateTax,
  ): Promise<CalculateTaxResult> {
    const url = `${this.apiEndpoint}/taxes/calculate`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: calculateTaxDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<CalculateTaxResult> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Customers from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of customers to return
   * @returns Promise object representing the CustomerPage
   * @throws Error if the request fails
   * @see CustomerPage
   */
  public async getCustomers({
    merchant_id,
    cursor,
    limit,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
  }): Promise<CustomerPage> {
    let url = `${this.apiEndpoint}/customers/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as CustomerPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Customer in the API.
   * @param createCustomerDto - CreateCustomer object
   * @returns Promise object representing the created Customer
   * @throws Error if the request fails
   * @see Customer
   * @see CreateCustomer
   */
  public async createCustomer(
    createCustomerDto: CreateCustomer,
  ): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createCustomerDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update an existing Customer in the API.
   * @param updateCustomerDto - UpdateCustomer object
   * @returns Promise object representing the updated Customer
   * @throws Error if the request fails
   * @see Customer
   * @see UpdateCustomer
   */
  public async updateCustomer(
    updateCustomerDto: UpdateCustomer,
  ): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateCustomerDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete a Customer from the API.
   * @param merchant_id - Merchant ID
   * @param id - Customer ID
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteCustomer({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Process a sale for loyalty points.
   * @param merchant_id - Merchant ID
   * @param updateLoyaltyAfterSaleOrRefundDto - UpdateLoyaltyAfterSaleOrRefund object
   * @returns Promise object representing the updated Customer
   * @throws Error if the request fails
   * @see Customer
   * @see UpdateLoyaltyAfterSaleOrRefund
   */
  public async updateLoyaltyAfterSaleOrRefund({
    merchant_id,
    updateLoyaltyAfterSaleOrRefundDto,
  }: {
    merchant_id: string;
    updateLoyaltyAfterSaleOrRefundDto: UpdateLoyaltyAfterSaleOrRefund;
  }): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty/process-receipt`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: updateLoyaltyAfterSaleOrRefundDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Redeem loyalty points for a customer.
   * @param merchant_id - Merchant ID
   * @param redeemPointsDto - RedeemPoints object
   * @returns Promise object representing the updated Customer
   * @throws Error if the request fails
   * @see Customer
   * @see RedeemPoints
   */
  public async redeemLoyaltyPoints({
    merchant_id,
    redeemPointsDto,
  }: {
    merchant_id: string;
    redeemPointsDto: RedeemPoints;
  }): Promise<Customer> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty/redeem-points`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: redeemPointsDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Customer> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a Loyalty Program by ID.
   * @param merchant_id - Merchant ID
   * @param id - Loyalty Program ID
   * @returns Promise object representing the Loyalty Program
   * @throws Error if the request fails
   * @see LoyaltyProgram
   */
  public async getLoyaltyProgram({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<LoyaltyProgram> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<LoyaltyProgram> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Loyalty Programs.
   * @param merchant_id - Merchant ID
   * @returns Promise object representing the LoyaltyProgramPage
   * @throws Error if the request fails
   * @see LoyaltyProgramPage
   */
  public async getLoyaltyPrograms({
    merchant_id,
  }: {
    merchant_id: string;
  }): Promise<LoyaltyProgramPage> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as LoyaltyProgramPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Loyalty Program.
   * @param merchant_id - Merchant ID
   * @param createLoyaltyProgramDto - CreateLoyaltyProgram object
   * @returns Promise object representing the created Loyalty Program
   * @throws Error if the request fails
   * @see LoyaltyProgram
   * @see CreateLoyaltyProgram
   */
  public async createLoyaltyProgram({
    merchant_id,
    createLoyaltyProgramDto,
  }: {
    merchant_id: string;
    createLoyaltyProgramDto: CreateLoyaltyProgram;
  }): Promise<LoyaltyProgram> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createLoyaltyProgramDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<LoyaltyProgram> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update an existing Loyalty Program.
   * @param merchant_id - Merchant ID
   * @param updateLoyaltyProgramDto - UpdateLoyaltyProgram object
   * @returns Promise object representing the updated Loyalty Program
   * @throws Error if the request fails
   * @see LoyaltyProgram
   * @see UpdateLoyaltyProgram
   */
  public async updateLoyaltyProgram({
    merchant_id,
    updateLoyaltyProgramDto,
  }: {
    merchant_id: string;
    updateLoyaltyProgramDto: UpdateLoyaltyProgram;
  }): Promise<LoyaltyProgram> {
    const url = `${this.apiEndpoint}/customers/${merchant_id}/loyalty`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateLoyaltyProgramDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<LoyaltyProgram> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Products from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of products to return
   * @param is_parent - Filter by parent products
   * @returns Promise object representing the ProductPage
   * @throws Error if the request fails
   * @see ProductPage
   */
  public async getProducts({
    merchant_id,
    cursor,
    limit,
    is_parent,
  }: GetProducts): Promise<ProductPage> {
    let url = `${this.apiEndpoint}/products/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
      is_parent: is_parent?.toString(),
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ProductPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single Product from the API.
   * @param merchant_id - Merchant ID
   * @param id - Product ID
   * @returns Promise object representing the Product
   * @throws Error if the request fails
   * @see Product
   */
  public async getProduct({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<Product> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Product> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Product in the API.
   * @param createProductDto - CreateProduct object
   * @returns Promise object representing the created Product
   * @throws Error if the request fails
   * @see Product
   * @see CreateProduct
   */
  public async createProduct(
    createProductDto: CreateProduct,
  ): Promise<Product> {
    const url = `${this.apiEndpoint}/products`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createProductDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Product> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update an existing Product in the API.
   * @param updateProductDto - UpdateProduct object
   * @returns Promise object representing the updated Product
   * @throws Error if the request fails
   * @see Product
   * @see UpdateProduct
   */
  public async updateProduct(
    updateProductDto: UpdateProduct,
  ): Promise<Product> {
    const url = `${this.apiEndpoint}/products/${updateProductDto.merchant_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateProductDto,
      extraHeaders: {},
    });

    try {
      console.log(config);
      const { data }: AxiosResponse<Product> = await axios.request(config);
      console.log(data);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete a Product from the API.
   * @param merchant_id - Merchant ID
   * @param id - Product ID
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteProduct({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Product Fraction.
   * @param merchant_id - Merchant ID
   * @param createProductFractionDto - CreateProductFraction object
   * @returns Promise object representing the created ProductFraction
   * @throws Error if the request fails
   * @see ProductFraction
   * @see CreateProductFraction
   */
  public async createProductFraction({
    merchant_id,
    createProductFractionDto,
  }: {
    merchant_id: string;
    createProductFractionDto: CreateProductFraction;
  }): Promise<ProductFraction> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/fractions`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createProductFractionDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<ProductFraction> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a Product Fraction by ID.
   * @param merchant_id - Merchant ID
   * @param id - Product Fraction ID
   * @returns Promise object representing the ProductFraction
   * @throws Error if the request fails
   * @see ProductFraction
   */
  public async getProductFraction({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<ProductFraction> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/fractions/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<ProductFraction> =
        await axios.request(config);

      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Product Fractions.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of fractions to return
   * @param fraction_id - Filter by fraction ID
   * @param parent_id - Filter by parent ID
   * @returns Promise object representing an array of ProductFractions
   * @throws Error if the request fails
   * @see ProductFraction[]
   */
  public async getProductFractions({
    merchant_id,
    cursor,
    limit,
    fraction_id,
    parent_id,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
    fraction_id?: string;
    parent_id?: string;
  }): Promise<ProductFractionPage> {
    let url = `${this.apiEndpoint}/products/${merchant_id}/fractions`;

    // Create query parameters object
    const params = {
      cursor,
      limit,
      fraction_id,
      parent_id,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);

    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ProductFractionPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update a Product Fraction.
   * @param merchant_id - Merchant ID
   * @param id - Product Fraction ID
   * @param updateProductFractionDto - UpdateProductFraction object
   * @returns Promise object representing the updated ProductFraction
   * @throws Error if the request fails
   * @see ProductFraction
   * @see UpdateProductFraction
   */
  public async updateProductFraction({
    merchant_id,
    id,
    updateProductFractionDto,
  }: {
    merchant_id: string;
    id: string;
    updateProductFractionDto: UpdateProductFraction;
  }): Promise<ProductFraction> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/fractions/${id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateProductFractionDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<ProductFraction> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete a Product Fraction.
   * @param merchant_id - Merchant ID
   * @param id - Product Fraction ID
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteProductFraction({
    merchant_id,
    id,
    parent_id,
  }: {
    merchant_id: string;
    id: string;
    parent_id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/fractions/${id}?parent_id=${parent_id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Store Product.
   * @param merchant_id - Merchant ID
   * @param createStoreProductDto - CreateStoreProduct object
   * @returns Promise object representing the created StoreProduct
   * @throws Error if the request fails
   * @see StoreProduct
   * @see CreateStoreProduct
   */
  public async createStoreProduct({
    merchant_id,
    createStoreProductDto,
  }: {
    merchant_id: string;
    createStoreProductDto: CreateStoreProduct;
  }): Promise<StoreProduct> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/store-products`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createStoreProductDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreProduct> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Store Product Changes
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @param storeProductChageDto - StoreProductChange object
   * @returns Promise object representing the StoreProductChangePage
   */

  public async getStoreProductChanges({
    merchant_id,
    storeProductChangeDto,
  }: {
    merchant_id: string;
    storeProductChangeDto: GetStoreProductChanges;
  }): Promise<StoreProductChangesPage> {
    let url = `${this.apiEndpoint}/products/${merchant_id}/changes/store-products`;

    // Create query parameters object
    // Generate query string from params
    const queryString = this.toQueryString(storeProductChangeDto);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreProductChangesPage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Store Products.
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of store products to return
   * @param is_parent - Filter by parent products
   * @returns Promise object representing the StoreProductPage
   * @throws Error if the request fails
   * @see StoreProductPage
   */
  public async getStoreProducts({
    merchant_id,
    store_id,
    cursor,
    limit,
    is_parent,
  }: {
    merchant_id: string;
    store_id: string;
    cursor?: string;
    limit?: number;
    is_parent?: boolean;
  }): Promise<StoreProductPage> {
    let url = `${this.apiEndpoint}/products/${merchant_id}/store-products/${store_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit: limit?.toString(),
      is_parent: is_parent?.toString(),
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as StoreProductPage;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a single Store Product.
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @param id - Store Product ID
   * @returns Promise object representing the StoreProduct
   * @throws Error if the request fails
   * @see StoreProduct
   */
  public async getStoreProduct({
    merchant_id,
    store_id,
    id,
  }: {
    merchant_id: string;
    store_id: string;
    id: string;
  }): Promise<StoreProduct> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/store-products/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreProduct> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update Store Product Inventory.
   * @param updateStoreProductInventoryDto - UpdateStoreProductInventory object
   * @returns Promise object representing the updated StoreProduct
   * @throws Error if the request fails
   * @see StoreProduct
   */

  public async updateStoreProductInventory(
    updateStoreProductInventoryDto: UpdateStoreProductInventory,
  ): Promise<StoreProduct> {
    const { merchant_id, store_id } = updateStoreProductInventoryDto;

    const url = `${this.apiEndpoint}/products/${merchant_id}/store-products/${store_id}/inventory`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateStoreProductInventoryDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreProduct> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update a Store Product.
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @param updateStoreProductDto - UpdateStoreProduct object
   * @returns Promise object representing the updated StoreProduct
   * @throws Error if the request fails
   * @see StoreProduct
   * @see UpdateStoreProduct
   */
  public async updateStoreProduct({
    merchant_id,
    store_id,
    updateStoreProductDto,
  }: {
    merchant_id: string;
    store_id: string;
    updateStoreProductDto: UpdateStoreProduct;
  }): Promise<StoreProduct> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/store-products/${store_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateStoreProductDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreProduct> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete a Store Product.
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @param id - Store Product ID
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteStoreProduct({
    merchant_id,
    store_id,
    id,
  }: {
    merchant_id: string;
    store_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/products/${merchant_id}/store-products/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get Inventory Changes.
   * @param merchant_id - Merchant ID
   * @param getInventoryChangesDto - GetInventoryChanges object
   * @returns Promise object representing the InventoryChangePage
   */

  public async getInventoryChanges({
    merchant_id,
    getInventoryChangesDto,
  }: {
    merchant_id: string;
    getInventoryChangesDto: GetInventoryChanges;
  }): Promise<InventoryChangePage> {
    let url = `${this.apiEndpoint}/inventory/${merchant_id}/changes`;

    // Create query parameters object
    // Generate query string from params
    const queryString = this.toQueryString(getInventoryChangesDto);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<InventoryChangePage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get Inventory of a Product.
   * @param merchant_id - Merchant ID
   * @param product_id - Product ID
   * @param store_id - Store ID
   * @returns Promise object representing the CurrentInventory
   */

  public async getInventory({
    merchant_id,
    product_id,
    store_id,
  }: {
    merchant_id: string;
    product_id: string;
    store_id: string;
  }): Promise<CurrentInventory> {
    const url = `${this.apiEndpoint}/inventory/${merchant_id}/stores/${store_id}/products/${product_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<CurrentInventory> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a new Inventory Change.
   * @param merchant_id - Merchant ID
   * @param createInventoryChangeDto - CreateInventoryChange object
   * @returns Promise object representing the created CurrentInventory
   */

  public async createInventoryChange({
    merchant_id,
    createInventoryChangeDto,
  }: {
    merchant_id: string;
    createInventoryChangeDto: CreateInventoryChange;
  }): Promise<CurrentInventory> {
    const { store_id, product_id } = createInventoryChangeDto;

    const url = `${this.apiEndpoint}/inventory/${merchant_id}/stores/${store_id}/products/${product_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createInventoryChangeDto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<CurrentInventory> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a list of Payment Types from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of payment types to return
   * @param stores - Filter by store IDs
   * @returns Promise object representing the PaymentTypePage
   */

  public async getPaymentTypes({
    merchant_id,
    cursor,
    limit = 50,
    stores,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
    stores?: string[];
  }): Promise<PaymentTypePage> {
    let url = `${this.apiEndpoint}/payment-types/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit,
      stores: stores?.join(','),
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PaymentTypePage;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single Payment Type from the API.
   * @param merchant_id - Merchant ID
   * @param id - Payment Type ID
   * @returns Promise object representing the PaymentType
   */

  public async getPaymentType({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<PaymentType> {
    const url = `${this.apiEndpoint}/payment-types/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PaymentType;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a new Payment Type in the API.
   * @param createPaymentTypeDto - CreatePaymentType object
   * @returns Promise object representing the created PaymentType
   */

  public async createPaymentType(
    createPaymentTypeDto: CreatePaymentType,
  ): Promise<PaymentType> {
    const url = `${this.apiEndpoint}/payment-types`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createPaymentTypeDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PaymentType;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update an existing Payment Type in the API.
   * @param updatePaymentTypeDto - UpdatePaymentType object
   * @returns Promise object representing the updated PaymentType
   */

  public async updatePaymentType(
    updatePaymentTypeDto: UpdatePaymentType,
  ): Promise<PaymentType> {
    const url = `${this.apiEndpoint}/payment-types`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updatePaymentTypeDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PaymentType;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Payment Type from the API.
   * @param merchant_id - Merchant ID
   * @param id - Payment Type ID
   */

  public async deletePaymentType({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/payment-types/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a list of Discounts from the API.
   * @param store_id - Store ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of discounts to return
   * @returns Promise object representing the DiscountPage
   */

  public async getDiscounts({
    store_id,
    cursor,
    limit = 50,
  }: {
    store_id: string;
    cursor?: string;
    limit?: number;
  }): Promise<DiscountPage> {
    let url = `${this.apiEndpoint}/discounts/${store_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as DiscountPage;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single Discount from the API.
   * @param store_id - Store ID
   * @param id - Discount ID
   * @returns Promise object representing the Discount
   */

  public async getDiscount({
    store_id,
    id,
  }: {
    store_id: string;
    id: string;
  }): Promise<Discount> {
    const url = `${this.apiEndpoint}/discounts/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Discount;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a new Discount in the API.
   * @param createDiscountDto - CreateDiscount object
   * @returns Promise object representing the created Discount
   */

  public async createDiscount(
    createDiscountDto: CreateDiscount,
  ): Promise<Discount> {
    const url = `${this.apiEndpoint}/discounts`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createDiscountDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Discount;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update an existing Discount in the API.
   * @param updateDiscountDto - UpdateDiscount object
   * @returns Promise object representing the updated Discount
   */

  public async updateDiscount(
    updateDiscountDto: UpdateDiscount,
  ): Promise<Discount> {
    const url = `${this.apiEndpoint}/discounts`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateDiscountDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Discount;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Discount from the API.
   * @param store_id - Store ID
   * @param id - Discount ID
   */

  public async deleteDiscount({
    store_id,
    id,
  }: {
    store_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/discounts/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Calculate the discount based on item price and discount type.
   * @param calculateDiscountDto - CalculateDiscount object
   * @returns Promise object representing the calculated discount
   */

  public async calculateDiscount(
    calculateDiscountDto: CalculateDiscount,
  ): Promise<CalculateDiscountResult> {
    const url = `${this.apiEndpoint}/discounts/calculate`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: calculateDiscountDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as CalculateDiscountResult;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a list of POS Devices from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of devices to return
   * @param store_id - Store ID
   * @returns Promise object representing the PosDevicePage
   */

  public async getPOSDevices({
    merchant_id,
    cursor,
    limit = 50,
    store_id,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
    store_id?: string;
  }): Promise<PosDevicePage> {
    let url = `${this.apiEndpoint}/pos-devices/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit,
      store_id,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PosDevicePage;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single POS Device from the API.
   * @param merchant_id - Merchant ID
   * @param id - POS Device ID
   * @returns Promise object representing the PosDevice
   */

  public async getPOSDevice({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<PosDevice> {
    const url = `${this.apiEndpoint}/pos-devices/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PosDevice;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a new POS Device in the API.
   * @param createPosDeviceDto - CreatePosDevice object
   * @returns Promise object representing the created PosDevice
   */
  public async createPOSDevice(
    createPosDeviceDto: CreatePosDevice,
  ): Promise<PosDevice> {
    const url = `${this.apiEndpoint}/pos-devices/${createPosDeviceDto.merchant_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createPosDeviceDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PosDevice;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update an existing POS Device in the API.
   * @param updatePosDeviceDto - UpdatePosDevice object
   * @returns Promise object representing the updated PosDevice
   */
  public async updatePOSDevice(
    updatePosDeviceDto: UpdatePosDevice,
  ): Promise<PosDevice> {
    const url = `${this.apiEndpoint}/pos-devices/${updatePosDeviceDto.merchant_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updatePosDeviceDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PosDevice;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a POS Device from the API.
   * @param merchant_id - Merchant ID
   * @param id - POS Device ID
   */
  public async deletePOSDevice({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/pos-devices/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a receipt threshold
   * @param createReceiptThresholdDto - CreateReceiptThreshold object
   * @returns Promise object representing the created ReceiptThreshold
   */

  public async createStoreThreshold(
    createThresholdDto: CreateThreshold,
  ): Promise<Threshold> {
    const url = `${this.apiEndpoint}/receipts/${createThresholdDto.merchant_id}/thresholds`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createThresholdDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);

      return data as Threshold;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a store threshold
   * @param merchant_id - Merchant ID
   * @param store_id - Store ID
   * @returns Promise object representing the Threshold
   */

  public async getStoreThreshold({
    merchant_id,
    store_id,
  }: {
    merchant_id: string;
    store_id: string;
  }): Promise<Threshold> {
    const url = `${this.apiEndpoint}/receipts/${merchant_id}/thresholds/${store_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Threshold;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update a store threshold
   * @param updateThresholdDto - UpdateThreshold object
   * @returns Promise object representing the updated Threshold
   */

  public async updateStoreThreshold(
    updateThresholdDto: UpdateThreshold,
  ): Promise<Threshold> {
    const url = `${this.apiEndpoint}/receipts/${updateThresholdDto.merchant_id}/thresholds`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateThresholdDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Threshold;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a list of Suppliers from the API.
   * @param merchant_id - Merchant ID
   * @param cursor - Cursor returned from a previous request
   * @param limit - Number of suppliers to return
   * @returns Promise object representing the SupplierPage
   */

  public async getSuppliers({
    merchant_id,
    cursor,
    limit = 50,
  }: {
    merchant_id: string;
    cursor?: string;
    limit?: number;
  }): Promise<SupplierPage> {
    let url = `${this.apiEndpoint}/suppliers/${merchant_id}`;

    // Create query parameters object
    const params = {
      cursor,
      limit,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as SupplierPage;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single Supplier from the API.
   * @param merchant_id - Merchant ID
   * @param id - Supplier ID
   * @returns Promise object representing the Supplier
   */

  public async getSupplier({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<Supplier> {
    const url = `${this.apiEndpoint}/suppliers/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Supplier;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a new Supplier in the API.
   * @param createSupplierDto - CreateSupplier object
   * @returns Promise object representing the created Supplier
   */

  public async createSupplier(
    createSupplierDto: CreateSupplier,
  ): Promise<Supplier> {
    const url = `${this.apiEndpoint}/suppliers`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: createSupplierDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Supplier;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update an existing Supplier in the API.
   * @param updateSupplierDto - UpdateSupplier object
   * @returns Promise object representing the updated Supplier
   */

  public async updateSupplier(
    updateSupplierDto: UpdateSupplier,
  ): Promise<Supplier> {
    const url = `${this.apiEndpoint}/suppliers`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: updateSupplierDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Supplier;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Supplier from the API.
   * @param merchant_id - Merchant ID
   * @param id - Supplier ID
   */

  public async deleteSupplier({
    merchant_id,
    id,
  }: {
    merchant_id: string;
    id: string;
  }): Promise<void> {
    const url = `${this.apiEndpoint}/suppliers/${merchant_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Authenticate a user
   * @param authenticateUserDto - AuthenticateUserRequest object
   * @returns Promise object representing the authenticated User
   * @throws Error if the request fails
   */

  public async authenticateUser(
    authenticateUserDto: AuthenticateUserRequest,
  ): Promise<AuthenticateUserResponse> {
    const url = `${this.apiEndpoint}/authentications/authenticate`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: authenticateUserDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as AuthenticateUserResponse;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Decrypt a user data
   * @param decryptUserDataDto - DecryptUserDataRequest object
   * @returns Promise object representing the decrypted User
   * @throws Error if the request fails
   */

  public async decryptUserData({
    encryptedCredentials,
  }: DecryptCredentialsRequestBody): Promise<EncryptCredentialsDto> {
    const url = `${this.apiEndpoint}/authentications/decrypt`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: { encryptedToken: encryptedCredentials },
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as EncryptCredentialsDto;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Sign out a user
   * @param signOutUserDto - SignOutRequestBody object
   * @returns Promise object representing the signed out User
   */

  public async signOutUser(
    signOutUserDto: SignOutUserRequestBody,
  ): Promise<SignOutUserResponse> {
    const url = `${this.apiEndpoint}/authentications/sign-out`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: signOutUserDto,
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as SignOutUserResponse;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all the resources
   * @param numItems - number of resources to get
   * @param lastCursor - last read cursor
   * @returns Promise object representing the resources fetched
   * @see ResourceList
   */

  public async getResources(
    numItems: number,
    lastCursor?: string,
  ): Promise<ResourceList> {
    let url = `${this.apiEndpoint}/authorizations/resources`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ResourceList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a principal by id
   * @param principalId - the id of the principal
   * @returns Promise object representing the principal fetched
   */

  public async getPrincipal(
    principalId: string,
  ): Promise<Principal | undefined> {
    const url = `${this.apiEndpoint}/authorizations/principals/${principalId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Principal;
    } catch (error) {
      this.logAxiosError(error);
      // throw error;
    }
  }

  /**
   * Get an Action by id
   * @param actionId - the id of the action
   * @returns Promise object representing the action fetched
   */

  public async getAction(actionId: string): Promise<Action> {
    const url = `${this.apiEndpoint}/authorizations/actions/${actionId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Action;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a Resource by id
   * @param resourceId - the id of the resource
   * @returns Promise object representing the resource fetched
   */

  public async getResource(resourceId: string): Promise<Resource> {
    const url = `${this.apiEndpoint}/authorizations/resources/${resourceId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Resource;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a Policy by id
   * @param policyId - the id of the policy
   * @returns Promise object representing the policy fetched
   */

  public async getPolicy(policyId: string): Promise<Policy> {
    const url = `${this.apiEndpoint}/authorizations/policies/${policyId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Policy;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a Role by id
   * @param roleId - the id of the role
   * @returns Promise object representing the role fetched
   */

  public async getRole(roleId: string): Promise<Role> {
    const url = `${this.apiEndpoint}/authorizations/roles/${roleId}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as Role;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all the actions
   * @param numItems - number of actions to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the actions fetched
   */

  public async getActions(
    numItems: number,
    lastCursor?: string,
  ): Promise<ActionList> {
    let url = `${this.apiEndpoint}/authorizations/actions`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ActionList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all policies
   * @param resourceId - the id of the resource
   * @param numItems - number of policies to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the policies fetched
   */

  public async getPolicies(
    numItems: number,
    lastCursor?: string,
  ): Promise<PolicyList> {
    let url = `${this.apiEndpoint}/authorizations/policies`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PolicyList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all policies with resource
   * @param resourceId - the id of the resource
   * @param numItems - number of policies to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the actions fetched
   */

  public async getPoliciesWithResource(
    resourceId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<PolicyList> {
    let url = `${this.apiEndpoint}/authorizations/resources/${resourceId}/policies`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PolicyList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all policies in role
   * @param roleId - the id of the role
   * @param numItems - number of policies to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the policies fetched
   */

  public async getPoliciesInRole(
    roleId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<PolicyList> {
    let url = `${this.apiEndpoint}/authorizations/roles/${roleId}/policies`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PolicyList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all roles with policy
   * @param policyId - the id of the policy
   * @param numItems - number of policies to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the roles fetched
   */

  public async getRolesWithPolicy(
    policyId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<RoleList> {
    let url = `${this.apiEndpoint}/authorizations/policies/${policyId}/roles`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as RoleList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all roles
   * @param merchantId - the id of the merchant
   * @param numItems - number of roles to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the roles fetched
   */

  public async getRoles(
    merchantId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<RoleList> {
    let url = `${this.apiEndpoint}/authorizations/merchants/${merchantId}/roles`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as RoleList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all resources in app
   * @param appName - the name of the app
   * @param numItems - number of resources to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the resources fetched
   */

  public async getResourcesInApp(
    appName: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<ResourceList> {
    let url = `${this.apiEndpoint}/authorizations/apps/${appName}/resources`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ResourceList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a Principal
   * @param principalId - principal id
   * @param merchantId - merchant id
   * @returns Promise object representing the principal created
   * @throws Error if the request fails
   */

  public async createPrincipal(
    principalId: string,
    merchantId: string,
  ): Promise<Principal> {
    const url = `${this.apiEndpoint}/authorizations/principals`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        principal_id: principalId,
        merchant_id: merchantId,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Principal> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create an Action
   * @param description - Action description
   * @param verb - Action verb
   * @returns Promise object representing the created Action
   * @throws Error if the request fails
   */

  public async createAction(
    description: string,
    verb: string,
    resourceId: string,
  ): Promise<Action> {
    const url = `${this.apiEndpoint}/authorizations/actions`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        description,
        verb,
        resource_id: resourceId,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Action> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Assign a Role to a Principal
   * @param principalId - principal id
   * @param roleId - role id
   * @returns Promise object representing the principal
   * @throws Error if the request fails
   */

  public async assignRole(
    principalId: string,
    roleId: string,
  ): Promise<Principal> {
    const url = `${this.apiEndpoint}/authorizations/principals/${principalId}/roles`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        role_id: roleId,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Principal> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Resource
   * @param description - Resource description
   * @param path - Resource path
   * @returns Promise object representing the created Resource
   * @throws Error if the request fails
   */

  public async createResource(
    description: string,
    path: string,
  ): Promise<Resource> {
    const url = `${this.apiEndpoint}/authorizations/resources`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        description,
        path,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Resource> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Policy
   * @param description - Resource description
   * @param actionId - id of the action
   * @param resourceId - id of the resource
   * @returns Promise object representing the created Policy
   * @throws Error if the request fails
   */

  public async createPolicy(
    description: string,
    merchantId: string,
    actionId: string,
    resourceId: string,
  ): Promise<Policy> {
    const url = `${this.apiEndpoint}/authorizations/policies`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        description,
        merchant_id: merchantId,
        resource_id: resourceId,
        action_id: actionId,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Policy> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Create a Role
   * @param description - description
   * @param merchantId - merchant id
   * @returns Promise object representing the role created
   * @throws Error if the request fails
   */

  public async createRole(
    description: string,
    merchantId: string,
  ): Promise<Role> {
    const url = `${this.apiEndpoint}/authorizations/roles`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        description,
        merchant_id: merchantId,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Role> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update an action
   * @param actionId - action id
   * @param description - description
   * @param verb - verb
   * @returns Promise object representing the Action
   * @throws Error if the request fails
   * @see Action
   */

  public async updateAction(
    actionId: string,
    description: string,
    verb: string,
  ): Promise<Action> {
    const url = `${this.apiEndpoint}/authorizations/actions/${actionId}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: {
        description,
        verb,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Action> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update a resource
   * @param resourceId - resource id
   * @param description - description
   * @param path - resource path
   * @returns Promise object representing the Resource
   * @throws Error if the request fails
   * @see Resource
   */

  public async updateResource(
    resourceId: string,
    description: string,
    path: string,
  ): Promise<Resource> {
    const url = `${this.apiEndpoint}/authorizations/resources/${resourceId}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: {
        description,
        path,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Resource> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Update a policy
   * @param policyId - resource id
   * @param description - description
   * @param actionId - action id
   * @param resourceId - resource id
   * @returns Promise object representing the Policy
   * @throws Error if the request fails
   * @see Policy
   */

  public async updatePolicy(
    policyId: string,
    description: string,
    actionId: string,
    resourceId: string,
  ): Promise<Policy> {
    const url = `${this.apiEndpoint}/authorizations/policies/${policyId}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: {
        action_id: actionId,
        resource_id: resourceId,
        description,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Policy> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete an Action
   * @param action_id - action id
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteAction(actionId: string): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/actions/${actionId}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Resource
   * @param resourceId - resource id
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteResource(resourceId: string): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/resources/${resourceId}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Policy
   * @param policyId - policy id
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deletePolicy(policyId: string): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/policies/${policyId}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Unassign a role
   * @param principalId - principal id
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async unassignRole(principalId: string): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/principals/${principalId}/roles`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Remove Policies from Role
   * @param roleId - role id
   * @param policies - list of policy ids that need to be removed
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async removePoliciesFromRole(
    roleId: string,
    policies: string[],
  ): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/roles/${roleId}/policies`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {
        policies,
      },
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Add policies to role
   * @param roleId - role id
   * @param policies - list of policy id's to be added to the role
   * @returns Promise object representing the updated role
   * @throws Error if the request fails
   */

  public async addPoliciesToRole(
    roleId: string,
    policies: string[],
  ): Promise<Role> {
    const url = `${this.apiEndpoint}/authorizations/roles/${roleId}/policies`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        policies,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Role> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get a Resource With Path
   * @param path - Resource path
   * @returns Promise object representing Resource
   * @throws Error if the request fails
   */

  public async getResourceWithPath(path: string): Promise<Resource> {
    const url = `${this.apiEndpoint}/authorizations/resources/paths`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: {
        path_name: path,
      },
      extraHeaders: {},
    });

    const { data }: AxiosResponse<Resource> = await axios.request(config);
    return data;
  }

  /**
   * Get all the actions in a resource
   * @param resourceId - the id of the resource
   * @param numItems - number of actions to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the actions fetched
   */

  public async getActionsInResource(
    resourceId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<ActionList> {
    let url = `${this.apiEndpoint}/authorizations/resources/${resourceId}/actions`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ActionList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all the principals in a role
   * @param roleId - the id of the role
   * @param numItems - number of principals to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the principals fetched
   */

  public async getPrincipalsInRole(
    roleId: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<PrincipalList> {
    let url = `${this.apiEndpoint}/authorizations/roles/${roleId}/principals`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as PrincipalList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update a role
   * @param roleId - the id of the role to update
   * @param description - the new description
   * @returns Promise object representing the Role
   * @throws Error if the request fails
   * @see Role
   */

  public async updateRoleDescription(
    roleId: string,
    description: string,
  ): Promise<Role> {
    const url = `${this.apiEndpoint}/authorizations/roles/${roleId}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: {
        description,
      },
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Role> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Delete role
   * @param roleId - role id
   * @returns Promise object
   * @throws Error if the request fails
   */
  public async deleteRole(roleId: string): Promise<void> {
    const url = `${this.apiEndpoint}/authorizations/roles/${roleId}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all actions with a description
   * @param description - the description
   * @param numItems - number of actions to get
   * @param last_cursor - last read cursor
   * @returns Promise object representing the actions fetched
   */
  public async getActionsWithDescription(
    description: string,
    numItems: number,
    lastCursor?: string,
  ): Promise<ActionList> {
    let url = `${this.apiEndpoint}/authorizations/descriptions/${encodeURIComponent(description)}`;

    const params = {
      num_items: numItems?.toString(),
      last_cursor: lastCursor,
    };

    // Generate query string from params
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data } = await axios.request(config);
      return data as ActionList;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a Purchase Order
   * POST {merchant_id}
   */
  public async createPurchaseOrder(
    merchant_id: string,
    dto: CreatePurchaseOrder,
  ): Promise<PurchaseOrder> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrder> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single Purchase Order
   * GET {merchant_id}/stores/{store_id}/{id}
   */
  public async getPurchaseOrder(
    merchant_id: string,
    store_id: string,
    id: string,
  ): Promise<PurchaseOrder> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/stores/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrder> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Receive items for a Purchase Order
   * POST {merchant_id}/receive
   */
  public async receivePurchaseOrderItems(
    merchant_id: string,
    dto: ReceivePurchaseOrder,
  ): Promise<PurchaseOrder> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/receive`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrder> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update a Purchase Order
   * PUT {merchant_id}
   */
  public async updatePurchaseOrder(
    merchant_id: string,
    dto: UpdatePurchaseOrder,
  ): Promise<PurchaseOrder> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrder> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all Purchase Orders
   * GET {merchant_id} with query parameters
   */
  public async getAllPurchaseOrders(
    merchant_id: string,
    params: GetAllPurchaseOrders,
  ): Promise<PurchaseOrderPage> {
    let url = `${this.apiEndpoint}/purchase-orders/${merchant_id}`;
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrderPage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete a Purchase Order
   * DELETE {merchant_id}/stores/{store_id}/{id}
   */
  public async deletePurchaseOrder(
    merchant_id: string,
    store_id: string,
    id: string,
  ): Promise<void> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/stores/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create a Purchase Order Payment
   * POST {merchant_id}/payments
   */
  public async createPurchaseOrderPayment(
    merchant_id: string,
    dto: CreatePurchaseOrderPayment,
  ): Promise<PurchaseOrderPayment> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/payments`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrderPayment> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get a single Purchase Order Payment
   * GET {merchant_id}/payments/stores/{store_id}/{id}
   */
  public async getPurchaseOrderPayment(
    merchant_id: string,
    store_id: string,
    id: string,
  ): Promise<PurchaseOrderPayment> {
    const url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/payments/stores/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrderPayment> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get all Purchase Order Payments
   * GET {merchant_id}/payments with query parameters
   */
  public async getAllPurchaseOrderPayments(
    merchant_id: string,
    params: Record<string, any> = {},
  ): Promise<PurchaseOrderPaymentPage> {
    let url = `${this.apiEndpoint}/purchase-orders/${merchant_id}/payments`;
    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<PurchaseOrderPaymentPage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update Store Inventory Value
   * POST {merchant_id}/inventory-value?store_id={store_id}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @returns Promise object representing the InventoryValue
   * @throws Error if the request fails
   * @see StoreInventoryValue
   */

  public async updateStoreInventoryValue(
    merchant_id: string,
    store_id: string,
  ): Promise<StoreInventoryValue> {
    const url = `${this.apiEndpoint}/reports/${merchant_id}/inventory-value?store_id=${store_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      extraHeaders: {},
      data: {},
    });

    try {
      const { data }: AxiosResponse<StoreInventoryValue> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);

      throw error;
    }
  }

  /**
   * Get Store Inventory Value
   * GET {merchant_id}/inventory-value/{store_id}?date={date}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param date - date
   * @returns Promise object representing the InventoryValue
   * @throws Error if the request fails
   */

  public async getStoreInventoryValue({
    date,
    merchant_id,
    store_id,
  }: {
    merchant_id: string;
    store_id: string;
    date: string;
  }): Promise<StoreInventoryValue> {
    const url = `${this.apiEndpoint}/reports/${merchant_id}/inventory-value/${store_id}?date=${date}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<StoreInventoryValue> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * 
   * export interface GetSalesReport {
  merchant_id: string;
  store_id: string;
  products?: string[];
  from?: string;
  to?: string;
  date?: string;
  by_date?: boolean;
  by_date_range?: boolean;
  by_products_and_date?: boolean;
  by_products_and_date_range?: boolean;
}
   */

  /**
   * Get Sales Report
   * GET reports/{merchant_id}/sales/{store_id}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param products - products
   * @param from - from
   * @param to - to
   * @param date - date
   * @param by_date - by date
   * @param by_date_range - by date range
   * @param by_products_and_date - by products and date
   * @param by_products_and_date_range - by products and date range
   * @returns Promise object representing the SalesReport
   * @throws Error if the request fails
   */

  public async getSalesReport(
    dto: GetSalesReport,
  ): Promise<AggregateSalesReport> {
    let url = `${this.apiEndpoint}/reports/${dto.merchant_id}/sales/${dto.store_id}`;

    // To query string

    const queryString = this.toQueryString(dto, true);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      extraHeaders: {},
      data: {},
    });

    try {
      const { data }: AxiosResponse<AggregateSalesReport> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get Sales Report By Interval
   * GET reports/{merchant_id}/sales/{store_id}/interval/{entity_type}/{entity_id}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param entity_type - entity type
   * @param entity_id - entity id
   * @param from - from
   * @param to - to
   * @param is_cumulative - is cumulative
   * @param interval - interval (daily, weekly, monthly)
   * @returns Promise object representing the SalesReport
   */

  public async getSalesReportByInterval({
    dto,
    merchant_id,
    store_id,
  }: {
    dto: GetSalesReportByDateRangeIntervalOptions;
    store_id: string;
    merchant_id: string;
  }): Promise<
    Array<
      | IntervalAggregateReport<AggregateStoreSalesReport>
      | IntervalAggregateReport<AggregateEmployeeSalesReport>
      | IntervalAggregateReport<AggregateProductSalesReport>
    >
  > {
    let url = `${this.apiEndpoint}/reports/${merchant_id}/sales/${store_id}/intervals/${dto.entityType}/${dto.entityId}`;

    const { from, to, is_cumulative, interval } = dto;

    // To query string

    const queryString = this.toQueryString(
      { from, to, is_cumulative, interval },
      true,
    );
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      extraHeaders: {},
      data: {},
    });

    try {
      const {
        data,
      }: AxiosResponse<
        Array<
          | IntervalAggregateReport<AggregateStoreSalesReport>
          | IntervalAggregateReport<AggregateEmployeeSalesReport>
          | IntervalAggregateReport<AggregateProductSalesReport>
        >
      > = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get Products Inventory Value
   * GET reports/{merchant_id}/inventory-value/{store_id}/products
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param date - date
   * @param cursor - cursor
   * @param limit - limit
   * @returns Promise object representing the InventoryValue
   * @throws Error if the request fails
   */

  public async getProductsInventoryValue(
    dto: GetProductsInventoryValue,
  ): Promise<ProductInventoryValuePage> {
    let url = `${this.apiEndpoint}/reports/${dto.merchant_id}/inventory-value/${dto.store_id}/products`;

    // To query string

    const queryString = this.toQueryString(
      {
        date: dto.date,
        limit: dto.limit,
        cursor: dto.cursor,
      },
      true,
    );
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      extraHeaders: {},
      data: {},
    });

    try {
      const { data }: AxiosResponse<ProductInventoryValuePage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Create an Inventory Template
   * POST /inventory-templates/{merchant_id}
   * @param merchant_id - merchant id
   * @param dto - CreateInventoryTemplate
   * @returns Promise object representing the InventoryTemplate
   * @throws Error if the request fails
   */

  public async createInventoryTemplate(
    merchant_id: string,
    dto: CreateInventoryTemplate,
  ): Promise<InventoryTemplate> {
    const url = `${this.apiEndpoint}/inventory-templates/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<InventoryTemplate> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get An Inventory Template
   * GET /inventory-templates/{merchant_id}/{store_id}/{id}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param id - id
   * @returns Promise object representing the InventoryTemplate
   * @throws Error if the request fails
   */

  public async getInventoryTemplate(
    merchant_id: string,
    store_id: string,
    id: string,
  ): Promise<InventoryTemplate> {
    const url = `${this.apiEndpoint}/inventory-templates/${merchant_id}/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<InventoryTemplate> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get All Inventory Templates
   * GET /inventory-templates/{merchant_id}
   * @param merchant_id - merchant id
   * @param GetInventoryTemplates - GetInventoryTemplates options
   * @returns Promise object representing the InventoryTemplatesPage
   * @throws Error if the request fails
   */

  public async getInventoryTemplates(
    merchant_id: string,
    params: GetInventoryTemplates,
  ): Promise<InventoryTemplatesPage> {
    let url = `${this.apiEndpoint}/inventory-templates/${merchant_id}`;

    // To query string

    const queryString = this.toQueryString(params);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<InventoryTemplatesPage> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update Inventory Template
   * PUT /inventory-templates/{merchant_id}
   * @param merchant_id - merchant id
   * @param dto - UpdateInventoryTemplate
   * @returns Promise object representing the InventoryTemplate
   * @throws Error if the request fails
   */

  public async updateInventoryTemplate(
    merchant_id: string,
    dto: UpdateInventoryTemplate,
  ): Promise<InventoryTemplate> {
    const url = `${this.apiEndpoint}/inventory-templates/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'PUT',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<InventoryTemplate> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Delete Inventory Template
   * DELETE /inventory-templates/{merchant_id}/{store_id}/{id}
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param id - id
   * @returns Promise void
   * @throws Error if the request fails
   */

  public async deleteInventoryTemplate(
    merchant_id: string,
    store_id: string,
    id: string,
  ): Promise<void> {
    const url = `${this.apiEndpoint}/inventory-templates/${merchant_id}/${store_id}/${id}`;

    const config = this.getRequestConfig({
      method: 'DELETE',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      await axios.request(config);
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get Debts
   * GET /debts/{merchant_id}
   * @param dto - GetDebts
   * @returns Promise object representing the DebtsPage
   * @throws Error if the request fails
   */

  public async getDebts(dto: GetDebts): Promise<DebtsPage> {
    let url = `${this.apiEndpoint}/debts/${dto.merchant_id}`;

    // To query string

    const queryString = this.toQueryString(dto);
    if (queryString) url += `?${queryString}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<DebtsPage> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Get Debtor Details
   * GET /debts/:merchant_id/:store_id/debtors/:debtor_type/:debtor_id
   * @param merchant_id - merchant id
   * @param store_id - store id
   * @param debtor_type - debtor type
   * @param debtor_id - debtor id
   * @returns Promise object representing the DebtorDetails
   * @throws Error if the request fails
   */

  public async getDebtorDetails({
    merchant_id,
    store_id,
    debtor_type,
    debtor_id,
  }: {
    merchant_id: string;
    store_id: string;
    debtor_type: EntityTypes;
    debtor_id: string;
  }): Promise<DebtorSummary> {
    const url = `${this.apiEndpoint}/debts/${merchant_id}/${store_id}/debtors/${debtor_type}/${debtor_id}`;

    const config = this.getRequestConfig({
      method: 'GET',
      url,
      data: {},
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<DebtorSummary> =
        await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }

  /**
   * Update Debt
   * POST /debts/{merchant_id}
   * @param merchant_id - merchant id
   * @param dto - DebtCommand
   * @returns Promise object representing the Debt
   */

  public async updateDebt(
    merchant_id: string,
    dto: DebtCommand,
  ): Promise<Debt> {
    const url = `${this.apiEndpoint}/debts/${merchant_id}`;

    const config = this.getRequestConfig({
      method: 'POST',
      url,
      data: dto,
      extraHeaders: {},
    });

    try {
      const { data }: AxiosResponse<Debt> = await axios.request(config);
      return data;
    } catch (error) {
      this.logAxiosError(error);
      throw error;
    }
  }
}
