// useSingleTabBlocker.tsx
import { useEffect } from 'react';
import { ulid } from 'ulidx';

// Function to sanitize the location so it can be used as part of localStorage keys
const sanitizeLocation = (): string =>
  window.location.origin.replace(/[^a-zA-Z0-9]/g, '');

// Key used in localStorage to track open tab IDs (rather than just a count)
const STORAGE_KEY = sanitizeLocation() + '-openTabs';

// Function to create the blocking overlay
const createBlockingOverlay = () => {
  // Prevent multiple overlays
  if (document.getElementById('tab-blocking-overlay')) return;

  const overlay = document.createElement('div');
  overlay.id = 'tab-blocking-overlay';
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
  overlay.style.color = 'white';
  overlay.style.display = 'flex';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.zIndex = '9999';
  overlay.style.fontSize = '1.5rem';
  overlay.style.textAlign = 'center';
  overlay.innerText = `Multiple tabs detected. Please keep only one tab open to use this application.`;
  document.body.appendChild(overlay);
};

// Function to remove the blocking overlay
const removeBlockingOverlay = () => {
  const overlay = document.getElementById('tab-blocking-overlay');
  if (overlay) {
    document.body.removeChild(overlay);
  }
};

export const useSingleTabRestriction = () => {
  useEffect(() => {
    // Each tab either uses an existing session ID or generates a new one
    const tabId = sessionStorage.getItem('tabId') || ulid();
    sessionStorage.setItem('tabId', tabId);

    // Parse the existing openTabs from localStorage, or start fresh
    const openTabsStr = localStorage.getItem(STORAGE_KEY);
    const openTabs: Record<string, number> = openTabsStr
      ? JSON.parse(openTabsStr)
      : {};

    // Record the timestamp for this tab
    openTabs[tabId] = Date.now();
    localStorage.setItem(STORAGE_KEY, JSON.stringify(openTabs));

    // Show the blocking overlay if there's more than one tab
    if (Object.keys(openTabs).length > 1) {
      createBlockingOverlay();
    } else {
      removeBlockingOverlay();
    }

    // Listen for changes in localStorage (e.g., other tabs closing)
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY) {
        const updatedTabsStr = localStorage.getItem(STORAGE_KEY);
        const updatedTabs: Record<string, number> = updatedTabsStr
          ? JSON.parse(updatedTabsStr)
          : {};

        if (Object.keys(updatedTabs).length > 1) {
          createBlockingOverlay();
        } else {
          removeBlockingOverlay();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Heartbeat: periodically update this tab’s timestamp
    const heartbeatInterval = setInterval(() => {
      const tabsStr = localStorage.getItem(STORAGE_KEY);
      const tabs: Record<string, number> = tabsStr ? JSON.parse(tabsStr) : {};
      tabs[tabId] = Date.now();
      localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
    }, 3000); // Update every 3 seconds (adjust as needed)

    // Periodically remove stale tabs (e.g., no heartbeat in >30s => assume closed)
    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      const STALE_LIMIT = 10000; // 00 seconds
      const tabsStr = localStorage.getItem(STORAGE_KEY);
      const tabs: Record<string, number> = tabsStr ? JSON.parse(tabsStr) : {};
      let changed = false;

      for (const id of Object.keys(tabs)) {
        if (now - tabs[id] > STALE_LIMIT) {
          delete tabs[id];
          changed = true;
        }
      }

      if (changed) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
      }
    }, 5000); // Check every 5 seconds

    // Clean up localStorage when this tab unloads
    const cleanup = () => {
      const tabsStr = localStorage.getItem(STORAGE_KEY);
      const tabs: Record<string, number> = tabsStr ? JSON.parse(tabsStr) : {};
      delete tabs[tabId];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
    };

    window.addEventListener('beforeunload', cleanup);

    // Cleanup on unmount
    return () => {
      cleanup();
      clearInterval(heartbeatInterval);
      clearInterval(cleanupInterval);
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);
};
