/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AdminUserAttributes,
  AuthType,
  CustomerUserAttributes,
  MerchantUserAttributes,
} from './auth.types';

export interface InventoryLevel {
  variant_id: string;
  store_id: string;
  in_stock: number;
  updated_at: string;
  update_id?: string; // Does not come with the webhook
}

export interface InventoryLevelsOutput {
  inventory_levels: InventoryLevel[];
}

export interface InventoryLevelProps {
  variant_id: string;
  store_id: string;
  stock_after: number;
}

export interface InventoryLevelsProps {
  inventory_levels: InventoryLevelProps[];
}

export interface BofrakUploadImageProps {
  image_url: string;
  item_id: string;
}

export interface BofrakOkResponse {
  OK: boolean;
  message?: string;
}

export interface Currency {
  code: string;
  decimal_places: number;
}

export interface BackupDto {
  sku: string;
  name: string;
  price: number;
  inventory: number;
  image_s3_key: string;
  old_image_url: string;
  merchant_id: string;
  trigger_image_backup: boolean;
  new_image_url?: string;
}

export interface BackupItem {
  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
  EntityType: EntityTypes;
  sku: string;
  name: string;
  price: number;
  inventory: number;
  image_s3_key: string;
  merchant_id: string;
  old_image_url: string;
  trigger_image_backup: boolean;
  new_image_url?: string;
}

export interface MerchantInfo {
  id: string;
  business_name: string;
  email: string;
  country: string;
  currency: Currency;
  created_at: string;
}

export interface WriteResponse {
  message: string;
  isSuccessful: boolean;
}

export enum GSI_DESCRIPTORS {
  CONFIRMATION_TYPE = 'CONFIRMATION_TYPE',
  REASON = 'REASON',
  SOURCE_TYPE = 'SOURCE_TYPE',
  DATE = 'DATE',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  CORRELATION = 'CORRELATION',
  DOMAIN = 'DOMAIN',
  PURCHASE_ORDER_COUNT = 'PURCHASE_ORDER_COUNT',
  PAYMENT_STATUS = 'PAYMENT_STATUS',
  PURCHASE_STATUS = 'PURCHASE_STATUS',
  LOAN_COUNT = 'LOAN_COUNT',
  PHONE_NUMBER = 'PHONE_NUMBER',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  YEAR_MONTH = 'YYMM',
  STATUS = 'STATUS',
  IS_MOTHER = 'IS_MOTHER',
  MAC_ADDRESS = 'MAC_ADDRESS',
  MOTHER = 'MOTHER',
  CHILD = 'CHILD',
  ENTITY_TYPE = 'ENTITY_TYPE',
  PURCHASE_ORDER_PAYMENT_SUMMARY_VERSION = 'PURCHASE_ORDER_PAYMENT_SUMMARY_VERSION',
  VERSION = 'VERSION',
  STORE_SALES_REPORT_VERSION = 'STORE_SALES_REPORT_VERSION',
  EMPLOYEE_SALES_REPORT_VERSION = 'EMPLOYEE_SALES_REPORT_VERSION',
  PRODUCT_SALES_REPORT_VERSION = 'PRODUCT_SALES_REPORT_VERSION',
  STORE_SALES_REPORT_SNAPSHOT_VERSION = 'STORE_SALES_REPORT_SNAPSHOT_VERSION',
  EMPLOYEE_SALES_REPORT_SNAPSHOT_VERSION = 'EMPLOYEE_SALES_REPORT_SNAPSHOT_VERSION',
  PRODUCT_SALES_REPORT_SNAPSHOT_VERSION = 'PRODUCT_SALES_REPORT_SNAPSHOT_VERSION',
  PRODUCT_INVENTORY_VALUE_VERSION = 'PRODUCT_INVENTORY_VALUE_VERSION',
  TOTAL_DUE = 'TOTAL_DUE',
  DUE_DATE = 'DUE_DATE',
  POS_RECEIPT = 'POS_RECEIPT',
  COGNITO_USER = 'COGNITO_USER',
  COGNITO_USERNAME = 'COGNITO_USERNAME',
  IS_PARENT = 'IS_PARENT',
  GLOBAL_SKU = 'GLOBAL_SKU',
  INVENTORY_TYPE = 'INVENTORY_TYPE',
  DEBT_STATUS = 'DEBT_STATUS',
  DEBT_ORIGIN = 'DEBT_ORIGIN',
  DEBT_VERSION = 'DEBT_VERSION',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EntityTypes {
  ACCOUNT = 'ACCOUNT',
  BACKUP = 'BACKUP',
  BORROWER_LOAN = 'BORROWER_LOAN',
  CURRENT_INVENTORY = 'CURRENT_INVENTORY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_DEBT = 'CUSTOMER_DEBT',
  CUSTOMER_LIABILITIES = 'CUSTOMER_LIABILITIES',
  DEBT = 'DEBT',
  DEBT_CHANGE = 'DEBT_CHANGE',
  DEBT_PAYMENT = 'DEBT_PAYMENT',
  DISCOUNT = 'DISCOUNT',
  EMPLOYEE_SALES_REPORT = 'EMPLOYEE_SALES_REPORT',
  EMPLOYEE_SALES_REPORT_SNAPSHOT = 'EMPLOYEE_SALES_REPORT_SNAPSHOT',
  ERROR = 'ERROR',
  EVENT = 'EVENT',
  IMAGE = 'IMAGE',
  INVENTORY_CHANGE = 'INVENTORY_CHANGE',
  INVENTORY_DISCREPANCY = 'INVENTORY_DISCREPANCY',
  INVENTORY_HISTORY = 'INVENTORY_HISTORY',
  INVENTORY_TEMPLATE = 'INVENTORY_TEMPLATE',
  ITEM_INVENTORY_VALUE = 'ITEM_INVENTORY_VALUE',
  LOAN = 'LOAN',
  LOYALTY_PROGRAM = 'LOYALTY_PROGRAM',
  MERCHANT = 'MERCHANT',
  PAYMENT = 'PAYMENT',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  POS_DEVICE = 'POS_DEVICE',
  PRODUCT = 'PRODUCT',
  PRODUCT_CHANGE = 'PRODUCT_CHANGE',
  PRODUCT_FRACTION = 'PRODUCT_FRACTION',
  PRODUCT_FRACTION_CHANGE = 'PRODUCT_FRACTION_CHANGE',
  PRODUCT_SALES_REPORT = 'PRODUCT_SALES_REPORT',
  PRODUCT_SALES_REPORT_SNAPSHOT = 'PRODUCT_SALES_REPORT_SNAPSHOT',
  PRODUCT_VERSION = 'PRODUCT_VERSION',
  PROMOTION = 'PROMOTION',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_ORDER_ITEM = 'PURCHASE_ORDER_ITEM',
  PURCHASE_ORDER_PAYMENT = 'PURCHASE_ORDER_PAYMENT',
  PURCHASE_ORDER_PAYMENT_HISTORY = 'PURCHASE_ORDER_PAYMENT_HISTORY',
  PURCHASE_ORDER_PAYMENT_SUMMARY = 'PURCHASE_ORDER_PAYMENT_SUMMARY',
  RECEIPT = 'RECEIPT',
  RECEIPT_HISTORY = 'RECEIPT_HISTORY',
  STORE = 'STORE',
  STORE_PRODUCT = 'STORE_PRODUCT',
  STORE_PRODUCT_CHANGE = 'STORE_PRODUCT_CHANGE',
  STORE_PRODUCT_VERSION = 'STORE_PRODUCT_VERSION',
  STORE_SALES_REPORT = 'STORE_SALES_REPORT',
  STORE_SALES_REPORT_SNAPSHOT = 'STORE_SALES_REPORT_SNAPSHOT',
  STORE_VALUE_SNAPSHOT = 'STORE_VALUE_SNAPSHOT',
  SUPPLIER = 'SUPPLIER',
  SYSTEM = 'SYSTEM',
  TAX = 'TAX',
  THRESHOLD = 'THRESHOLD',
  USER = 'USER',
}

export enum RelationshipTypes {
  MOTHER = 'MOTHER',
  CHILD = 'CHILD',
  INVENTORY_VARIANT = 'INVENTORY_VARIANT',
}

export enum DefaultPricingType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export interface Paginated {
  cursor?: string | null | undefined;
  count: number;
}

export interface EmployeePage {
  employees: Employee[];
  cursor?: string;
  count: number;
}

export enum Form {
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
  SUN = 'SUN',
  OCTAGON = 'OCTAGON',
}

export enum Color {
  GREY = 'GREY',
  RED = 'RED',
  PINK = 'PINK',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
}

export interface Component {
  variant_id: string;
  quantity: number;
}

export type UpdateAvailabilityPropsStore = {
  store_id: string;
  available_for_sale: boolean;
};

export type UpdateAvailabilityPropsVariant = {
  variant_id: string;
  item_id: string;
  stores: UpdateAvailabilityPropsStore[];
};

export interface Timestamps {
  /**
   * The ISO 8601 formatted date-time string indicating when this payment type was created.
   * @example "2020-03-25T19:55:23.077Z"
   */
  created_at: string;

  /**
   * The ISO 8601 formatted date-time string indicating when this payment type was last updated.
   * @example "2020-03-30T08:05:10.020Z"
   */
  updated_at: string;

  /**
   * The ISO 8601 formatted date-time string indicating when this payment type was deleted.
   * This field is optional and will be present only if the payment type has been deleted.
   * @example "2020-04-02T23:45:20.050Z"
   */
  deleted_at?: string | null;
}

export type AuthUser =
  | CustomerUserAttributes
  | MerchantUserAttributes
  | AdminUserAttributes;

export interface Employee extends Timestamps {
  id: string;
  name: string;
  roles: string[];
  username: string;
  email: string;
  phone_number: string;
  stores: string[];
  merchant_id: string;
  is_active: boolean;
  is_inactive_since?: string;
  cognito_id: string | null | undefined;
  cognito_username: string | null | undefined;
  auth_user?: AuthUser;
}

export interface EmployeeSummary {
  id: string;
  name: string;
}

export enum ErrorCodes {
  NOT_FOUND = 'NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  BAD_REQUEST = 'BAD_REQUEST',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  INVALID_REQUEST = 'INVALID_REQUEST',
  INVALID_COMMAND = 'INVALID_COMMAND',
  CONFLICTING_REQUEST = 'CONFLICTING_REQUEST',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NO_RESPONSE = 'NO_RESPONSE',
}

export interface DateRange {
  to: string;
  from: string;
}

export enum ReceiptType {
  SALE = 'SALE',
  REFUND = 'REFUND',
}

export interface TotalMoneyRange {
  to: number;
  from: number;
}

export interface EventBusDto {
  data: any;
  metadata: Record<string, unknown>;
}

export enum ShopAndSmileEventSource {
  SHOP_AND_SMILE = 'shop-and-smile',
  SHOP_AND_SMILE_PRODUCTS = 'shop-and-smile.products',
  SHOP_AND_SMILE_INVENTORY = 'shop-and-smile.inventory',
  SHOP_AND_SMILE_RECEIPTS = 'shop-and-smile.receipts',
  SHOP_AND_SMILE_REPORTS = 'shop-and-smile.reports',
}

export interface PaginationParams {
  cursor?: string | null | undefined;
  limit?: number;
}

export interface UploadImageToItemProps {
  item_id: string;
  image_url: string;
}

export interface Connection {
  userId: string;
  connectionId: string;
  connected: boolean;
  stage: string;
  domainName: string;
}

export interface ConnectionItem {
  PK: string;
  SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
  GSI1PK: string;
  GSI1SK: string;
  userId: string;
  connectionId: string;
  connected: boolean;
  stage: string;
  domainName: string;
}

export interface SSMParameterConfig {
  path: string;
  outputKey: string;
  description?: string;
  secure?: boolean;
}

// Define the user roles as an enum
export enum UserRole {
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
  WAITER = 'WAITER',
  SYSTEM = 'SYSTEM',
  CASHIER = 'CASHIER',
  RECEIPT_CHECKER = 'RECEIPT_CHECKER',
  INVENTORY_COUNTER = 'INVENTORY_COUNTER',
  SUPPLIER = 'SUPPLIER',
}

export enum PaymentName {
  CASH = 'Cash',
  DEBT = 'Divida',
  BANK_CARD = 'Bank Card',
  MOBILE_MONEY = 'Mobile Money',
  EMOLA = 'Emola',
  MPEZA = 'Mpeza',
  CARD = 'Card',
  SPLIT = 'Split',
}

// Define the user interface
export interface User {
  name: string;
  surname: string;
  phonenumber: string;
  code: string;
  user_id: string;
  role: UserRole;
  auth_type?: AuthType;
}

export interface CreateUserProps {
  name: string;
  surname: string;
  phonenumber?: string;
  code: string;
  role: UserRole;
  merchant_id: string;
  stores: string[];
}

export interface ReceiptQueryParams extends PaginationParams {
  receipt_numbers?: string;
  since_receipt_number?: string;
  before_receipt_number?: string;
  store_id?: string;
  order?: string;
  updated_at_min?: string;
  updated_at_max?: string;
  created_at_min?: string;
  created_at_max?: string;
}

export interface CommonEmployeeAsCreatorAttributes {
  creator_id: string;
  creator_type: EntityTypes;
  creator_name: string;
}

export interface UserStore {
  merchant_id: string;
  store_id: string;
  user_id: string;
}

export interface CognitoStoresAttribute extends Timestamps {
  stores: UserStore[];
}

export enum StringBoolean {
  TRUE = 'true',
  FALSE = 'false',
}

export interface ChangedAttributes {
  [key: string]: {
    old: any;
    new: any;
  };
}
