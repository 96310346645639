import { colors } from '@bofrak-backend/shared';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { FaInfoCircle } from 'react-icons/fa';

interface BlinkingDotProps {
  isGreen: boolean;
  enableBlinking: boolean;
  message?: string;
}

// Define the pulse (radiating) animation.
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const BlinkingDot = ({
  isGreen,
  enableBlinking,
  message,
}: BlinkingDotProps) => {
  // Prepare a common style object that applies the animation when enabled.
  const animationStyle = enableBlinking
    ? {
        animation: `${pulseAnimation} 3s infinite`,
      }
    : {};

  const dot = message ? (
    <IconButton
      aria-label="Info"
      size="xx-small"
      icon={
        <div style={{ color: isGreen ? 'green.500' : colors.red }}>
          {' '}
          {/* This div sets the color */}
          <FaInfoCircle />
        </div>
      }
      p={0}
      bg="transparent"
      sx={animationStyle} // Chakra's sx prop to apply custom styles including our animation.
    />
  ) : (
    <div
      style={{
        margin: '0',
        padding: '0',
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        backgroundColor: isGreen ? colors.green : colors.red,
        ...animationStyle,
      }}
    />
  );

  return message ? (
    <Popover>
      <PopoverTrigger>{dot}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{message}</PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    dot
  );
};
