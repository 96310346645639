// Import statements
import {
  AggregateEmployeeSalesReport,
  formatCurrency,
} from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

// Custom table header component
const CustomTh = chakra('th', {
  baseStyle: {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

// TableRow component
const TableRow: React.FC<AggregateEmployeeSalesReport> = ({
  cost_of_products,
  employee_name,
  expenses,
  gross_sales,
  net_sales,
  refunds,
  discounts,
  net_profit,
}) => {
  const capitalizeAllFirstLetters = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <Tr fontSize={'sm'}>
      <Td>
        <Text fontWeight={'bold'} fontSize={'xs'} textAlign={'center'}>
          {capitalizeAllFirstLetters(employee_name) ?? 'UNKNOWN'}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(gross_sales)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(net_sales)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(refunds)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(discounts)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(expenses)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(cost_of_products)}
        </Text>
      </Td>
      <Td>
        <Text fontSize={'xs'} textAlign={'center'}>
          {formatCurrency(net_profit)}
        </Text>
      </Td>
    </Tr>
  );
};

// InventoryHistoryTable component
export const EmployeeSalesReportTable: React.FC<{
  data: AggregateEmployeeSalesReport[];
}> = ({ data }) => {
  return data.length ? (
    <TableContainer width="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr fontSize={'sm'}>
            <CustomTh>Name</CustomTh>
            <CustomTh>Gross Sales</CustomTh>
            <CustomTh>Net Sales</CustomTh>
            <CustomTh>Refunds</CustomTh>
            <CustomTh>Discounts</CustomTh>
            <CustomTh>Expenses</CustomTh>
            <CustomTh>Cost of Products</CustomTh>
            <CustomTh>Net Profit</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <TableRow key={row.employee_id} {...row} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Alert textTransform={'capitalize'} width={'auto'} status="info">
      <AlertIcon />
      No data available
    </Alert>
  );
};
