import { Employee } from './shared.types';

export enum AuthType {
  Admin = 'Admin',
  Customer = 'Customer',
  Merchant = 'Merchant',
}

export enum CognitoAttributeDataType {
  STRING = 'String',
  NUMBER = 'Number',
  DATETIME = 'DateTime',
  BOOLEAN = 'Boolean',
}

export interface CognitoAttributeDefinition {
  AttributeDataType?: CognitoAttributeDataType;
  Mutable: boolean;
  Name: string;
  Required?: boolean;
}

export interface MerchantAuthStore {
  store_id: string;
  store_name: string;
  merchant_id: string;
  roles: string[];
}

export interface MerchantAuthStoreWithRoles {
  store_id: string;
  roles: string[];
}

export interface AuthenticateUserResponse {
  encryptedCredentials: string;
  userData: MerchantUserAttributes;
  employee: Employee;
  credentials: EncryptCredentialsDto;
}

export interface AuthenticateUserRequest {
  phone_number: string;
  pin: string;
  client_id: string;
}

export interface DecryptCredentialsRequestBody {
  encryptedCredentials: string;
}

export interface SignOutUserRequestBody {
  accessToken: string;
}

export interface SignOutUserResponse {
  message: string;
}

export interface CreateMerchantUserRequestBody {
  phone_number: string;
  email?: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  auth_type: AuthType;
  business_name: string;
  created_by: string;
  stores: MerchantAuthStore[];
  pin: string;
}

export interface CreateAdminUserRequestBody {
  phone_number: string;
  email: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  auth_type: AuthType;
  password: string;
}

export interface UpdateAdminUserRequestBody {
  user_id: string;
  email: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  stores?: MerchantAuthStore[];
}

export interface CreateAuthUserResponse {
  message: string;
  userSub?: string;
}

export interface CustomerAuthStore {
  store_id: string;
  store_name: string;
  merchant_id: string;
}

export interface CustomerUserAttributes {
  username: string;
  phone_number: string;
  email: string;
  address: string;
  family_name: string;
  given_name: string;
  gender: string;
  profile_picture: string;
  auth_type: AuthType;
  created_by: string;
  pin: string;
  stores: CustomerAuthStore[];
}

export interface MerchantUserAttributes {
  username: string;
  phone_number: string;
  email: string;
  address: string;
  family_name: string;
  given_name: string;
  auth_type: AuthType;
  business_name: string;
  created_by: string;
  pin: string;
  stores: MerchantAuthStore[];
}

export interface AdminUserAttributes {
  username: string;
  phone_number: string;
  email: string;
  address: string;
  family_name: string;
  given_name: string;
  auth_type: AuthType;
}

export interface UpdateMerchantUserRequestBody {
  user_id: string;
  email?: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  business_name?: string;
  pin?: string;
  add_to_stores?: MerchantAuthStore[];
  remove_from_stores?: MerchantAuthStore[];
  is_email_verified?: boolean;
  add_to_roles?: MerchantAuthStoreWithRoles[];
  remove_from_roles?: MerchantAuthStoreWithRoles[];
}

export interface CreateCustomerUserRequestBody {
  phone_number: string;
  email?: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  auth_type: AuthType;
  created_by: string;
  stores: MerchantAuthStore[];
  pin: string;
  gender?: string;
  profile_picture?: string;
}

export interface UpdateCustomerUserRequestBody {
  user_id: string;
  email?: string;
  address?: string;
  family_name?: string;
  given_name?: string;
  stores?: CustomerAuthStore[];
  pin?: string;
  gender?: string;
  profile_picture?: string;
}

export interface UpdateAuthUserResponse {
  message: string;
}

export type UserAttribute = { Name: string; Value: string };

export interface ResetUserPasswordRequestBody {
  user_id: string;
  new_pin?: string;
  auth_type: AuthType;
  new_password?: string;
}

export interface EncryptCredentialsDto {
  AccessToken: string;
  ExpiresIn: number;
  Employee: Employee;
  TokenType: string;
}
