export const paths = {
  home: '/',
  dashboard: '/dashboard',
  emailVerification: '/email-verification',
  forgotPassword: '/forgot-password',
  register: '/register',
  newStore: '/new-store',
};

export const envVars = {
  TYPESENSE_HOST: import.meta.env.VITE_TYPESENSE_HOST,
  TYPESENSE_SEARCH_ONLY_KEY: import.meta.env.VITE_TYPESENSE_SEARCH_ONLY_KEY,
  TYPESENSE_INDEX_NAME: import.meta.env.VITE_TYPESENSE_INDEX_NAME,
  CLOUDFRONT_PHOTO_URL: import.meta.env.VITE_CLOUDFRONT_PHOTO_URL,
  S3_BUCKET_FOLDER_NAME: import.meta.env.VITE_S3_BUCKET_FOLDER_NAME,
  ADMIN_PASSWORD: import.meta.env.VITE_ADMIN_PASSWORD,
  API_URL: import.meta.env.VITE_BASE_URL,
  LOYVERSE_STORE: import.meta.env.VITE_LOYVERSE_STORE,
  STAGE: import.meta.env.VITE_STAGE,
  MERCHANT_NAME: import.meta.env.VITE_MERCHANT_NAME,
  REGION: import.meta.env.VITE_REGION,
  COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  USER_POOL_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
  COGNITO_IDENTITY_POOL_ID: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
};

export const CLOUDFRONT_PHOTO_URL = envVars.CLOUDFRONT_PHOTO_URL;
export const S3_BUCKET_FOLDER_NAME = envVars.S3_BUCKET_FOLDER_NAME;
